import {Authenticated} from 'src/router/middlewares'

export default [
    {
        path: '/foodies',
        name: 'foodies.index',
        component: () => import('src/pages/foodies/Index'),
        meta: {
            title: 'Lista de foodies',
            middleware: [Authenticated]
        }
    },
    {
        path: '/foodies/:foodieId',
        name: 'foodies.update',
        component: () => import('src/pages/foodies/update'),
        meta: {
            title: 'Actualizar foodie',
            middleware: [Authenticated]
        }
    }
]
