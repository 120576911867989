import {Authenticated} from 'src/router/middlewares'

export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('src/pages/dashboard/Index'),
        meta: {
            title: 'Dashboard',
            middleware: [Authenticated]
        }
    }
]