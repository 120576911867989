export default ({axios, mapQueryString, withoutInvalidValues}) => ({
    list: (query = {}) => axios.get(`/foodies${mapQueryString(withoutInvalidValues(query))}`),
    show: (id, query = {}) => axios.get(`/foodies/${id}${mapQueryString(query)}`),
    delete: (id) => axios.delete(`/foodies/${id}`),
    restore: (id) => axios.post(`/foodies/${id}/restore`),
    update: (id, body) => axios.patch(`/foodies/${id}`, body),
    collaborations: (id, query = {}, config = {}) => {
        axios.get(`/foodies/${id}/collaborations${mapQueryString(query)}`, config)
    },
    balanceMovements: (id, body = {}) => axios.post(`/foodies/${id}/balance-movements`, body),
    balanceMovementsList: (id, query = {}) => axios.get(`/foodies/${id}/balance-movements${mapQueryString(query)}`, )
})
