export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('src/pages/auth/Login'),
        meta: {
            title: 'Login',
        }
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('src/pages/auth/PasswordReset'),
        meta: {
            title: 'Password Reset',
        }
    },
    {
        path: '/confirm-password-reset',
        name: 'confirm-password-reset',
        component: () => import('src/pages/auth/ConfirmPasswordReset'),
        meta: {
            title: 'Confirm Password Reset',
        }
    }
]