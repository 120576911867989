import {Authenticated} from 'src/router/middlewares'

export default [
    {
        path: '/collaborations',
        name: 'collaborations.index',
        component: () => import('src/pages/collaborations/Index'),
        meta: {
            title: 'Lista de colaboraciones',
            middleware: [Authenticated]
        }
    },
    {
        path: '/collaborations/:id',
        name: 'collaborations.show',
        component: () => import('src/pages/collaborations/Show'),
        meta: {
            title: 'Detalle de colaboración',
            middleware: [Authenticated]
        }
    },
    {
        path: '/collaborations/:id/edit',
        name: 'collaborations.edit',
        component: () => import('src/pages/collaborations/Edit'),
        meta: {
            title: 'Actualizar colaboración',
            middleware: [Authenticated]
        }
    }
]
