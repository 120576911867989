import {Authenticated} from 'src/router/middlewares'

export default [
    {
        path: '/restaurants',
        name: 'restaurants.index',
        component: () => import('src/pages/restaurants/Index'),
        meta: {
            title: 'Lista de restaurantes',
            middleware: [Authenticated]
        }
    },
    {
        path: '/restaurants/:restaurantId',
        name: 'restaurants.update',
        component: () => import('src/pages/restaurants/update'),
        meta: {
            title: 'Actualizar restaurante',
            middleware: [Authenticated]
        }
    },
    {
        path: '/restaurants/create',
        name: 'restaurants.create',
        component: () => import('src/pages/restaurants/create'),
        meta: {
            title: 'Crear restaurante',
            middleware: [Authenticated]
        }
    }
]
