import config from 'src/config'

/**
 * This is the main router configuration,
 * this handles every hook of the vue-router environment.
 */

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) {
    return context.next
  }

  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({
      ...context,
      next: nextMiddleware
    })
  }
}

export default (router) => {
  router.beforeEach((to, from, next) => {

    document.title = to.meta?.title || config.APP_TITLE

    if (to.meta?.middleware) {
      const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
      const context = {to, from, next, router}
      const nextMiddleware = nextFactory(context, middleware, 1)

      return middleware[0]({
        ...context,
        next: nextMiddleware
      })
    }

    return next()
  })
}
