export default ({axios, mapQueryString, withoutInvalidValues}) => ({
    list: (query = {}) => axios.get(`/restaurants${mapQueryString(withoutInvalidValues(query))}`),
    show: (id, query = {}) => axios.get(`/restaurants/${id}${mapQueryString(query)}`),
    update: (id, body) => axios.patch(`/restaurants/${id}`, body),
    create: (body) => axios.post('/admin/restaurants', body),
    delete: (id) => axios.delete(`/restaurants/${id}`),
    restore: (id) => axios.post(`/restaurants/${id}/restore`),
    verify: (id, body = {}) => axios.patch(`/restaurants/${id}/verify`, body),

    uploadCoverPicture: (id, formData) => {
        return axios({
            method: 'POST',
            url: `/restaurants/${id}/upload-cover-picture`,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
        })
    },

    collaborations: (id) => axios.get(`/restaurants/${id}/collaborations`),

    updateSettings: (id, data) => axios.patch(`/restaurants/${id}/settings`, data),
    skipSubscription: (id) => axios.post(`/restaurants/${id}/skip-subscription`),
    removeSkipSubscription: (id) => axios.post(`/restaurants/${id}/remove-skip-subscription`),
})
