import {Authenticated} from 'src/router/middlewares'

export default [
    {
        path: '/bookings',
        name: 'bookings.index',
        component: () => import('src/pages/bookings/index'),
        meta: {
            title: 'Listado de reservas',
            middleware: [Authenticated]
        }
    },
]