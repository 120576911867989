import moment from "moment";

export function cleanLocalAppData() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
}

export function dateToLocalTime(date) {
    return moment(date).local().format('YYYY-MM-DD HH:mm:ss')
}

export function dateToLocalTimeAM(date) {
    return moment(date).local().format('YYYY-MM-DD hh:mm A')
}