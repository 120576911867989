import config from 'src/config'
import {parseValidationErrors} from "../repositories/api";
import {cleanLocalAppData} from "../utils";

/**
 * implemented at ~/main.js
 */
export default (axios) => {
    axios.defaults.baseURL = config.API_BASE

    /**
     * the config must always be returned
     * @description this function is called after the request is sent
     * @return {JSON} config
     * @throws {Response} error
     */
    axios.interceptors.response.use(response => {
        if (response.headers.authorization) {
            const token = response.headers.authorization.split(' ')[1];
            localStorage.setItem('access_token', token)
        }

        return response
    }, error => {
        /**
         * to start the axios request validation we must start validating
         * that the response is readable, if its readable we can proceed
         * to read the status codes.
         */
        if ('response' in error) {
            if (error.response?.data?.message === 'The token has been blacklisted') {
                cleanLocalAppData()
            }

            switch (error.response.status) {
                case 400:
                case 403:
                case 402:
                case 401:
                case 422:
                    break
                default:
                    break
            }

            error.response._validations = null;
            error.response._error_code = null;
            error.response._error_details = null;

            if (error.response?.data?.errors) {
                error.response._validations = parseValidationErrors(error.response?.data?.errors)
            }

            if (error.response?.data?.error_code) {
                error.response._error_code = error.response?.data?.error_code
            }

            if (error.response?.data?.description) {
                error.response._error_details = error.response?.data?.description
            }

            // console.log(`Request: ${error.config.method.toUpperCase()} ${error.config.url}`, {
            //     data: error.response?.data,
            //     status: error.response?.status,
            //     _validations: error.response?._validations,
            //     _error_code: error.response._error_code,
            //     _details: error.response._error_details
            // });
        } else {
            // eslint-disable-next-line no-empty
            if (!axios.isCancel(error)) {

            }
        }

        return Promise.reject(error)
    })

    /**
     * the config must always be returned
     * @description this function is called before send the request to the server
     * @return {JSON} config
     * @throws {Response} error
     */
    axios.interceptors.request.use(config => {
        // const auth = JSON.parse(localStorage.getItem('auth'))
        const token = localStorage.getItem('access_token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        if (config.customHeaders) {
            config.headers = {
                ...config.headers,
                ...config.customHeaders
            }
        }

        config.url = encodeURI(config.url)

        return config
    }, error => Promise.reject(error))
}
