<template>
  <div class="layout-profile">
    <!--    <div>-->
    <!--      <img src="assets/layout/images/profile.png" alt=""/>-->
    <!--    </div>-->
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ user.name ?? '{username}' }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li>
          <button class="p-link">
            <i class="pi pi-fw pi-user"></i><span>Account</span>
          </button>
        </li>

        <!--        <li>-->
        <!--          <button class="p-link">-->
        <!--            <i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">1</span>-->
        <!--          </button>-->
        <!--        </li>-->

        <li>
          <button class="p-link" @click="logout">
            <i class="pi pi-fw pi-power-off"></i><span>Logout</span></button>
        </li>
      </ul>
    </transition>

  </div>
</template>

<script>
import {authUser} from "src/constants";
import {cleanLocalAppData} from "src/utils";

export default {
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    async logout() {
      try {
        await this.$repository.auth.logout();

        cleanLocalAppData();

        this.$router.push('login');
      } catch (err) {
        console.log(err);

        cleanLocalAppData();

        this.$router.push('login');
      }

    },
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    }
  },
  computed: {
    user() {
      return authUser();
    }
  }
}
</script>

<style scoped>
</style>