<template>
  <div>
    <component :is="`${shouldUseAuthLayout() ? 'auth' : 'admin'}-layout`"/>
  </div>
</template>

<script>
import AdminLayout from "src/pages/_layouts/AdminLayout";
import AuthLayout from "src/pages/_layouts/AuthLayout";

export default {
  methods: {
    shouldUseAuthLayout() {
      const routes = [
          '/login',
          '/password-reset',
          '/confirm-password-reset',
      ];

      return routes.includes(this.$router.currentRoute.value.path);
    }
  },
  components: {
    AdminLayout,
    AuthLayout
  }
}
</script>

<style scoped>

</style>
