/**
 * API User Types definitions
 */
const USER_TYPE_FOODIE = 1;
const USER_TYPE_RESTAURANT = 2;
const USER_TYPE_ADMIN = 3;
const USER_TYPE_SUPER_ADMIN = 4;

export const UserTypes = {
    foodie: USER_TYPE_FOODIE,
    restaurant: USER_TYPE_RESTAURANT,
    admin: USER_TYPE_ADMIN,
    super_admin: USER_TYPE_SUPER_ADMIN,
}

export function authUser() {
    const raw = localStorage.getItem('user');
    const user = raw ? JSON.parse(raw) : null;

    if (user === null) {
        return {};
    }

    return {
        ...user, ...{
            isAdmin: UserTypes.admin === user.type,
            isFoodie: UserTypes.foodie === user.type,
            isRestaurant: UserTypes.restaurant === user.type,
            isSuperAdmin: UserTypes.super_admin === user.type,
        }
    }
}

export function accessToken() {
    const token = localStorage.getItem('access_token');

    if (token === null) {
        return null;
    }
}
